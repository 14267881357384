/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-22 11:37:42
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-23 17:15:00
 * @FilePath: \chai-activity-h5\src\router\permission.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from './index'
import store, { useWechatStore } from '@/store'
const checkWechatBlack = ['/', '/index', '/index/:id']
router.beforeEach(async (to, from, next) => {
  const userStore = useWechatStore(store)

  if (!userStore.isWechat) {
    await userStore.initWechat(checkWechatBlack.includes(to.path))
    next()
  }
  next()
})
